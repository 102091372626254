<template>
  <div class="body">
    <!-- <div class="authentication">
    </div> -->
    <div style="text-align: center;padding-top: 80px;">
      <van-image
	  style="width: 64px;height: 64px;"
          :src="bgUrl"
      ></van-image>
    </div>
	<div style="text-align: center;margin-top: 12px;font-size: 18px;color: #333333;">绑定成功</div>
	
	<div style="text-align: center;margin-bottom: 80px;margin-top: 40px;">
		<van-button plain style="width: 145px;height: 48px;border-radius: 4px;border: 1px solid #999999;color: #333333;" @click="bind">继续绑定</van-button>
		<van-button plain style="width: 145px;height: 48px;border-radius: 4px;border: 1px solid #999999;margin-left: 20px;color: #333333;" @click="goToPay">去缴费</van-button>
	</div>
	
	<div style="margin-left: 30px;margin-bottom: 16px;">已绑定户号</div>
	
	<div v-for="item in userList" style="margin: 0 16px 16px 16px;background: #F2F7FF;border-radius: 4px;padding: 12px;display: flex;align-items: center;">
		<div>
			<div style="color: #333333;">户名：{{item.userName}}</div>
			<div style="color: #333333;margin-top: 4px;">户号：{{item.waterCode}}</div>
		</div>
		<div style="margin-left: auto;text-align: center;">
			<van-button @click="unbind(item)" plain style="width: 100px;height: 32px;border-radius: 4px;border: 1px solid #0069FF;margin-left: 20px;color: #0069FF;font-size: 12px;">解除绑定</van-button>
		</div>
	</div>
	
	<!-- <van-row v-for="item in userList" type="flex" justify="center" style="margin: 10px;">
	  <van-col span="9">户号: {{item.waterCode}}</van-col>
	  <van-col span="9">户名: {{item.userName}}</van-col>
	  <van-col span="6"><van-button round type="warning" size="small" @click="unbind(item)">解除绑定</van-button></van-col>
	</van-row> -->
	<!-- <div style="text-align: center;margin-top: 20px;margin-bottom: 100px;font-weight: bold;font-size: 18px;">
		已绑定户名：{{waterCode}}
	</div> -->
	<!-- <div style="bottom: 10px;">
		<van-button round type="primary" size="large" @click="bind">继续绑定</van-button>
	</div> -->
  </div>
</template>

<script>
	import request from "@/api/index.js";
export default {
  name: '',
  components: {

  },
  props: {},
  data() {
    return {
		openId: '',
		userList: []
    }
  },
  computed: {
    bgUrl(){
      return require("@/assets/img/auth_success_new.png")
    }
  },
  watch: {
  },
  created() {
	this.openId = this.$route.query.openId
	this.queryBindUserListByOpenId()
  },
  mounted() {

  },
  onLoad(options) {

  },
  methods: {
	  goToPay(){
		  this.$router.push({path: '/'})
	  },
	unbind(item){
		this.$dialog.confirm({
			title: '提示',
			message: '是否解除当前户号绑定？',
		}).then(() => {
		    // on confirm
			let data={
				waterCode: item.waterCode,
				userName: item.userName,
				openId: item.publicAccountOpenId
			}
			request.post('/wechat/publicAccount/unbindOpenIdWithWaterCodeAndUserName', data).then(res=>{
				if(res.code == 200){
					this.$toast.success('解除绑定成功')
					this.queryBindUserListByOpenId()
				}else{
					this.$toast.fail(res.msg)
				}
			})
		}).catch(() => {
		    // on cancel
		});

	},
	bind(){
		this.$router.push({path: '/bindWechatOpenIdWithWaterCode', query: {openId: this.openId}})
	},
	queryBindUserListByOpenId(){
		let data = {
		  openId: this.openId
		}
		request.post('/wechat/publicAccount/queryBindUserListByOpenId', data).then(res=>{
			if(res.code == 200){
				this.userList = res.data
			}else{
				this.$toast.fail(res.msg)
			}
		})
	}
  },
}
</script>
<style lang='scss' scoped>
.body{
  /* background-color: white; */
}
.connect{
  text-align: center;
  margin-top:30px;
  color: blue;
}
.authentication{
  text-align: center;
  padding-top:10px;
  font-size: 25px;
  font-weight: bold;
}
.scan-img{
  
}
.posture{
  opacity: 0.9;
  width: 20rem;
  height:10rem;
  margin: auto;
}
.button{
  margin-top:30px;
}
</style>
